import { fetch as fetchPolyfill } from "whatwg-fetch";

export const fetchJSON = async (
  endpoint: string,
  method: "POST" | "GET",
  body?: any,
  headers?: HeadersInit,
  mode?: RequestMode
): Promise<any> => {
  return fetchPolyfill(endpoint, {
    method,
    headers: {
      "Content-Type": `application/json; charset=utf-8`,
      Accept: "application/json",
      Pragma: "no-cache", // for Internet Explorer 11
      enctype: "multipart/form-data",
      encoding: "multipart/form-data",
      ...headers
    },
    body: JSON.stringify(body),
    mode
  })
    .then((res: any) => res.json())
    .then((res: any) => {
      return res;
    })
    .catch((error: Error) => {
      throw error;
    });
};

export const fetchBlob = (
  endpoint: string,
  method: "POST" | "GET",
  body?: any,
  fileName?: string,
  accept?: string
): Promise<any> => {
  return fetchPolyfill(endpoint, {
    method,
    headers: {
      "Content-Type": `${accept || "application/json"}; charset=utf-8`,
      Accept: accept || "application/json",
      Pragma: "no-cache" // for Internet Explorer 11
    },
    body: body && JSON.stringify(body)
  })
    .then((res: any) => res.blob())
    .then((blob: any) => {
      console.log(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);

      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName ? `${fileName}` : "Document.zip";
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error: Error) => {
      throw error;
    });
};

export const fetchImport = (endpoint: string, method: "POST" | "GET", body?: any): Promise<any> => {
  return fetchPolyfill(endpoint, {
    method,
    headers: {
      Accept: "application/json",
      Pragma: "no-cache", // for Internet Explorer 11
      enctype: "multipart/form-data",
      encoding: "multipart/form-data"
    },
    body
  })
    .then((res: any) => res.json())
    .then((res: any) => {
      return res;
    })
    .catch((error: Error) => {
      throw error;
    });
};
