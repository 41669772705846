// HOME
export const GET_LAYOUT_DATA = `/Home/LayoutData`;
export const GET_INDEX_DATA = `/Home/IndexData`;
export const GET_NEWS_DATA = `/Home/GetNews`;
export const GET_SEARCH_RESULTS = `/Home/SearchAutocomplete`;
export const GET_SEARCH_DATA = `/Home/GetSearch`;
export const GET_PROD_DETAILS = `/Home/GetProductDetails`;
export const SEND_SEARCH_TRACK = `/Home/SearchAutocomplete?search=`;
export const GET_REGION_LIST = "/Home/GetRegionList";
export const GET_STATE_LIST = "/Home/GetStateList";
export const GET_USER_LIST = "/Home/GetUserList";
export const GET_COUNTRY_LIST = "/Home/GetCountryList";

// USER
export const POST_USER_SAVE_SETTING = `/User/SaveSetting`;
export const CHECK_DUPLICATES = `/User/CheckDuplicatedNameAndEmail`;
export const SAVE_USER = `/User/SaveUser`;

// TOOL
export const GET_TRANSLATIONS = (lang: string) => `/Home/GetTranslationsByLanguage?language=${lang}`;
export const GET_CONFIGURATOR_LIST = `/Tool/GetConfiguratorListData`;
export const GET_CALCULATION_NEW = `/Tool/GetCalculationNew`;
export const GET_CONFIGURATOR_NEW = `/Tool/GetConfiguratorNew`;
export const GET_SOLUTIONS = `/Tool/GetSolutionList`;
export const GET_RECALCULATE_NEW = `/Tool/RecalculateNew`;
export const GET_CONFIGURATOR_TYPES = `/Tool/GetAvailableConfiguratorTypes`;
export const TOOL_CLONE_STEP = `/Tool/CloneStep`;
export const TOOL_DELETE_STEP = `/Tool/DeleteStep`;
export const SEND_GA_GROUP_LIST = `/Tool/GetConfiguratorGroupListByGroupId`;
export const ADD_PICTURE = `/Tool/AddPicture`;
export const GET_ENVIRONMENT_LIST = "/Tool/GetEnvironmentList";
export const GET_USER_CONFIGURATOR_LIST = "/Tool/GetUserConfiguratorList";
export const SAVE_QUOTE = `/Tool/SaveQuote`;
export const SAVE_QUOTE_PARTIAL = `/Tool/SaveQuotePartial`;
export const ADD_MORE_CALCULATION = `/Tool/ConfiguratorList/0`;
export const DELETE_QUOTE = `/Tool/DeleteQuote`;
export const QUOTE_LIST = `/Tool/QuoteList`;
export const QUOTE_DATA = `/Tool/GetQuoteFullData`;
export const DELETE_CALCULATION = `/Tool/SaveQuotePartial`;
export const QUOTE_CALCULATION = `/Tool/Calculation`;
export const COPY_CALCULATION = `/Tool/CopyCalculation`;
export const RENAME_CALCULATION = `/Tool/RenameCalculation`;

// QUOTE LIST
export const GET_QUOTE_LIST = `GetQuoteList`;
export const GET_FOLDER_LIST = `GetFolderList`;
export const SAVE_FOLDER = `SaveFolder`;
export const MOVE_TO_FOLDER = `SaveQuotesPartial`;
export const DELETE_QUOTES = `DeleteQuotes`;
export const DELETE_FOLDER = `DeleteFolder`;

// LOGIN
export const LOGIN_URL = "/User/Login";
export const LOGIN_API = "/User/LoginAPI";
export const REGISTER_URL = "/User/Register";
export const REGISTER_API = "/User/RegisterAPI";
export const REGISTER_INIT_API = "/User/RestoreInitAPI";
export const RESTORE_URL = "/User/Restore";
export const RESTORE_API = "/User/RestoreAPI";
export const RESET_API = "/User/ResetAPI";
export const CONFIRM_EMAIL_API = "/User/ConfirmEmailAPI";

// INTEGRATION QUOTE
export const GET_LAYOUT_INT_QUOTE_DATA = `/Integration/QuoteInit`;
export const INTEGRATION_QUOTE = `/Integration/Quote`;
export const GET_RECALCULATE_NEW_INTEGRATION = (guid: string) => `/Integration/Quote/${guid}${GET_RECALCULATE_NEW}`;
export const ADD_PICTURE_INTEGRATION = (guid: string) => `/Integration/Quote/${guid}${ADD_PICTURE}`;

// SUPPORT
export const GET_SUPPORT_WHATS_NEW = "/Home/SupportWhatsNew";
export const GET_SUPPORT_VIDEOS = "/Home/SupportVideos";
export const GET_SUPPORT_FAQS = "/Home/SupportFaqs";
export const GET_SUPPORT_NEWS = "/Home/SupportNews";

// ADMIN
export const GET_STATISTICS_LIST = "/Admin/GetStatisticsList";
export const ADMIN_DASHBOARD = "/Admin/Dashboard";
export const USER_MANAGER = "/Admin/UserManager";
export const LANGUAGE_LIST = "/Admin/GetLanguageList";
export const RESOURCE_LIST = "/Admin/GetResourceList";

export const SAVE_RESOURCE = "/Admin/SaveResource";
export const DETELE_RESOURCE = "/Admin/DeleteResource";
export const ENVIRONMENT_MANAGER = "/Admin/EnvironmentManager";
export const CONFIGURATOR_MANAGER = "/Admin/ConfiguratorManager";
export const PORTFOLIO_MANAGER = "/Admin/PortfolioManager";
export const CONTENT_MANAGER = "/Admin/ContentManager";
export const RESOURCE_MANAGER = "/Admin/ResourceManager";
export const DIAGRAM_EDITOR = "/Admin/DiagramEditor";
export const COORDINATES_MANAGER = "/Admin/DiagramCoordinatesManager";

// FILE
export const GET_QUOTE_DOCUMENTS = `/File/GetQuoteFullDocuments`;
export const SENG_QUOTE_TO_USER = `/File/SendQuoteToUser`;
export const RESOURCE_FILE_LIST = "/File/GetResources";
export const IMPORT_RESOURCE_FILE = "/File/SaveResourceExcel";
export const USERS_STATISTICS = "/File/GetStatistics?type=0";
export const QUOTES_STATISTICS = "/File/GetStatistics?type=1";
export const GET_TOOL_DOCUMENT = "/File/GetToolDocument";
export const GET_DANAQUA_DOCUMENT = `/File/GetDanAquaDocument`;
