import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import { ThemeProvider } from "@danfoss/cooling-ui/lib/styled";
import { Alert } from "@danfoss/cooling-ui/lib/components/Alert";
import defaultTheme from "@danfoss/cooling-ui/lib/themes/default";
import { GET_LAYOUT_DATA, GET_TRANSLATIONS, GET_LAYOUT_INT_QUOTE_DATA } from "const/ENDPOINTS";
import LayoutDataInterface from "interfaces/LayoutDataInterface";
import TranslationsInterface from "interfaces/TranslationsInterface";
import BreadcrumbsInterface from "interfaces/BreadcrumbsInterface";
import React, { lazy, Suspense, useEffect, useState, createContext, useCallback } from "react";
import { createPortal, render } from "react-dom";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import "styles/css/homepage.css";
import "styles/css/tether.css";
import "styles/css/font-awesome.css";
import "styles/css/bootstrap.css";
import "styles/css/jquery.fileupload-ui.css";
import "styles/css/responsive.css";
import "styles/css/init.css";
import "styles/css/helpers.css";
import "styles/css/input.css";
import "styles/css/site.css";
import "styles/fonts.css";
import { GlobalStyle } from "styles/global.css";
import { fetchJSON } from "utils/fetchUtils";
import {
  currentEnvId,
  getConfiguratorTypes,
  getLanguageCookie,
  initEnvironment,
  initLanguage,
  setEnvironmentAsInternalAfterLogin,
  setLanguage
} from "utils/layoutUtils";
import { sendPageViewToGA } from "utils/gaUtils";
import { getTranslation } from "utils/translationsUtils";

const HeaderComponent = lazy(() => import("../Header/Header"));
const FooterComponent = lazy(() => import("../Footer/Footer"));
export const HomepageComponent = lazy(() => import("components/Home/Index/Index"));
const SearchComponent = lazy(() => import("components/Home/Search/Search.jsx"));
const LoginRouterComponent = lazy(() => import("components/Home/Login/LoginRouter"));
export const ConfiguratorListContainerComponent = lazy(() =>
  import("components/Tool/ConfiguratorList/ConfiguratorListContainer")
);
export const ConfiguratorNewComponent = lazy(() => import("components/Tool/ConfiguratorNew/ConfiguratorNew.jsx"));
export const ToolListComponent = lazy(() => import("components/Tool/Tool/ToolList"));
export const ToolComponent = lazy(() => import("components/Tool/Tool/Tool"));
export const ProductComponent = lazy(() => import("components/Tool/Tool/Product"));
export const SubstationSelectorComponent = lazy(() => import("components/Tool/Tool/SubstationSelector"));
const QuoteListComponent = lazy(() => import("components/QuoteList/QuoteList"));
export const ConfiguratorToolsComponent = lazy(() => import("components/Tool/ConfiguratorTools/ConfiguratorTools"));
const IntegrationQuoteComponent = lazy(() => import("components/IntegrationQuote/IntegrationQuote"));
const SupportComponent = lazy(() => import("components/Home/Support/Support"));
const CookiesComponent = lazy(() => import("components/Home/Index/Cookies/Cookies"));

const DashboardComponent = lazy(() => import("components/Admin/Dashboard/Dashboard"));
const UserManagerComponent = lazy(() => import("components/Admin/UserManager/UserManager"));
const QuoteComponent = lazy(() => import("components/Tool/Quote/Quote"));

const ResourceManagerComponent = lazy(() => import("components/Admin/ResourceManager/ResourceManager"));

export const createSection = (component: any) => {
  return <>{createPortal(component, document.getElementById("ScrollContent"))}</>;
};

export const IntegrationQuoteContext = createContext(null);

const LayoutComponent: React.FC = () => {
  // TODO: Make context for passing common data (Proposal)
  const [translations, setTranslations] = useState<Array<TranslationsInterface>>();
  const [breadcrumbs, setBreadcrumbs] = useState<Array<BreadcrumbsInterface>>();
  const [layoutData, setLayoutData] = useState<LayoutDataInterface>();
  const [loading, setLoading] = useState(true);
  const [loadingHeader, setLoadingHeader] = useState(true);
  const [, setLoadingFooter] = useState(true);
  const [configuratorTypes, setConfiguratorTypes] = useState();
  const [lang, setLang] = useState("");

  const [integrationPath, setIntegrationPath] = useState("");
  const [integrationProject, setIntegrationProject] = useState("");
  const [isIntegrationView, setIntegrationView] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const configurationTools = false;
  const isIntQuotePath = window.location.pathname.includes("/Integration/Quote");

  const initData = async () => {
    initLanguage();
    initEnvironment();
    const layoutData = await fetchJSON(GET_LAYOUT_DATA, "GET");
    const configuratorTypes = await getConfiguratorTypes(currentEnvId());
    const translations = await fetchJSON(GET_TRANSLATIONS(getLanguageCookie()), "GET");
    setTranslations(translations);
    setLayoutData(layoutData);
    setConfiguratorTypes(configuratorTypes);
    setLang(layoutData.language);
    setBreadcrumbs([]);
    setEnvironmentAsInternalAfterLogin();
  };

  const initDataQuote = async () => {
    const guid = isIntQuotePath ? window.location.pathname.split("/")[3] : null;
    try {
      const quoteInt = await fetchJSON(`${GET_LAYOUT_INT_QUOTE_DATA}/${guid}`, "POST");

      initLanguage();
      initEnvironment();
      const layoutData = await fetchJSON(GET_LAYOUT_DATA, "GET");
      const configuratorTypes = await getConfiguratorTypes(parseInt(quoteInt.EnvironmentId, 10));
      const translations = await fetchJSON(GET_TRANSLATIONS(quoteInt.Language), "GET");
      setTranslations(translations);
      setLayoutData(layoutData);
      setConfiguratorTypes(configuratorTypes);
      setLang(quoteInt.Language);

      setLanguage(quoteInt.Language);
      setIntegrationProject(quoteInt.ProjectNumber);
      setIntegrationPath(window.location.pathname);
      setIntegrationView(true);
      setBreadcrumbs([]);
    } catch {
      const translations = await fetchJSON(GET_TRANSLATIONS(getLanguageCookie()), "GET");
      setAlertMessage(getTranslation(translations, "INTEGRATION_QUOTE_FETCH_ERROR"));
    }
  };

  useEffect(() => {
    if (!isIntQuotePath) {
      initData();
    }
  }, []);

  useEffect(() => {
    if (isIntQuotePath) {
      initDataQuote();
    }
  }, []);

  useEffect(() => {
    if (translations && layoutData && configuratorTypes) {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (window.location.pathname === "/") {
      sendPageViewToGA();
    }
  }, []);

  const clearAlertMessage = useCallback(() => {
    setAlertMessage("");
  }, [alertMessage]);

  const createSection = (component: any) => {
    return <>{createPortal(component, document.getElementById("ScrollContent"))}</>;
  };

  useEffect(() => {
    if (!loadingHeader) {
      document.getElementById("ScrollContent").style.opacity = "1";
    }
  }, [loadingHeader]);

  const integrationContext = {
    isIntegrationView,
    integrationPath,
    integrationProject,
    setIntegrationPath
  };

  return (
    <>
      {alertMessage && (
        <ThemeProvider theme={defaultTheme}>
          <Alert message={alertMessage} closable onClose={() => clearAlertMessage()} />
        </ThemeProvider>
      )}
      {!loading && (
        <IntegrationQuoteContext.Provider value={integrationContext}>
          <ThemeProvider theme={defaultTheme}>
            <Suspense fallback="">
              <GlobalStyle />
              <>
                {createPortal(
                  <HeaderComponent
                    translations={translations}
                    breadcrumbs={breadcrumbs}
                    layoutData={layoutData}
                    loading={setLoadingHeader}
                  />,
                  document.getElementById("Header")
                )}
                <Router>
                  <Switch>
                    <Route exact path="/(Home|Home/Index)">
                      <Redirect to="/" />
                    </Route>
                    <Route path="/User/">
                      {createSection(
                        <LoginRouterComponent
                          translations={translations}
                          currentUserLanguage={lang.toLowerCase()}
                          countries={layoutData.countries}
                        />
                      )}
                    </Route>
                    <Route exact path="/">
                      {createSection(
                        <HomepageComponent translations={translations} configuratorTypes={configuratorTypes} />
                      )}
                    </Route>
                    <Route path="/Integration/Quote/:guid">
                      {createSection(
                        <IntegrationQuoteComponent
                          translations={translations}
                          configuratorTypes={configuratorTypes}
                          configurationTools={configurationTools}
                          setBreadcrumbs={setBreadcrumbs}
                          language={lang}
                        />
                      )}
                    </Route>
                    <Route path="/Admin/Dashboard">
                      {createSection(
                        <DashboardComponent translations={translations} setBreadcrumbs={setBreadcrumbs} />
                      )}
                    </Route>
                    <Route path="/Admin/ResourceManager">
                      {createSection(
                        <ResourceManagerComponent
                          translations={translations}
                          setBreadcrumbs={setBreadcrumbs}
                          language={lang}
                        />
                      )}
                    </Route>
                    <Route path="/Admin/UserManager">
                      {createSection(
                        <UserManagerComponent translations={translations} setBreadcrumbs={setBreadcrumbs} />
                      )}
                    </Route>
                    <Route path="/Home/Search">{createSection(<SearchComponent translations={translations} />)}</Route>
                    <Route path="/Home/Support">
                      {createSection(<SupportComponent translations={translations} language={lang} />)}
                    </Route>
                    <Route path="/Home/Cookies">
                      {createSection(<CookiesComponent translations={translations} language={lang} />)}
                    </Route>
                    <Route exact path="/Tool/ConfiguratorList/:id?/:project?">
                      {createSection(
                        <ConfiguratorListContainerComponent
                          translations={translations}
                          configurationTools={configurationTools}
                          language={lang}
                          setBreadcrumbs={setBreadcrumbs}
                        />
                      )}
                    </Route>
                    <Route exact path="/Tool/ToolList">
                      {createSection(<ToolListComponent translations={translations} />)}
                    </Route>
                    <Route path="/Tool/Tool/:id?">
                      {createSection(<ToolComponent translations={translations} setBreadcrumbs={setBreadcrumbs} />)}
                    </Route>
                    <Route path="/Tool/Dimensioning">
                      {createSection(<ToolComponent translations={translations} setBreadcrumbs={setBreadcrumbs} />)}
                    </Route>
                    <Route path="/Tool/Dimensioning/:name">
                      {createSection(<ToolComponent translations={translations} setBreadcrumbs={setBreadcrumbs} />)}
                    </Route>
                    <Route path="/Tool/Product/:name">
                      {createSection(<ProductComponent translations={translations} setBreadcrumbs={setBreadcrumbs} />)}
                    </Route>
                    <Route path="/Tool/SubstationSelector/:id">
                      {createSection(
                        <SubstationSelectorComponent translations={translations} setBreadcrumbs={setBreadcrumbs} />
                      )}
                    </Route>
                    <Route path="/Tool/NominalSize">
                      {createSection(<ToolComponent translations={translations} setBreadcrumbs={setBreadcrumbs} />)}
                    </Route>
                    <Route path="/Tool/WaterState">
                      {createSection(<ToolComponent translations={translations} setBreadcrumbs={setBreadcrumbs} />)}
                    </Route>
                    <Route path="/Tool/SteamState">
                      {createSection(<ToolComponent translations={translations} setBreadcrumbs={setBreadcrumbs} />)}
                    </Route>
                    <Route path="/Tool/ReturnDHW">
                      {createSection(<ToolComponent translations={translations} setBreadcrumbs={setBreadcrumbs} />)}
                    </Route>
                    <Route path="/Tool/ThermoDual_Recalculation">
                      {createSection(<ToolComponent translations={translations} setBreadcrumbs={setBreadcrumbs} />)}
                    </Route>
                    <Route path="/Tool/HEX_demo">
                      {createSection(<ToolComponent translations={translations} setBreadcrumbs={setBreadcrumbs} />)}
                    </Route>

                    <Route exact path="/Tool/ConfiguratorTools">
                      {createSection(
                        <ConfiguratorToolsComponent
                          translations={translations}
                          language={lang}
                          setBreadcrumbs={setBreadcrumbs}
                        />
                      )}
                    </Route>
                    <Route path="/Tool/ConfiguratorNew">
                      {createSection(
                        <ConfiguratorNewComponent translations={translations} setBreadcrumbs={setBreadcrumbs} />
                      )}
                    </Route>
                    <Route path="/Tool/Quote">
                      {createSection(
                        <QuoteComponent
                          translations={translations}
                          language={lang}
                          setBreadcrumbs={setBreadcrumbs}
                          currentUser={layoutData.currentUser}
                        />
                      )}
                    </Route>
                    <Route path="/Tool/CalculationNew/:id/:project">
                      {createSection(
                        <ConfiguratorNewComponent translations={translations} setBreadcrumbs={setBreadcrumbs} />
                      )}
                    </Route>
                    <Route path="/Tool/QuoteList">
                      {createSection(
                        <QuoteListComponent translations={translations} setBreadcrumbs={setBreadcrumbs} />
                      )}
                    </Route>
                  </Switch>
                </Router>
                {!isIntegrationView &&
                  createPortal(
                    <FooterComponent
                      translations={translations}
                      layoutData={layoutData}
                      loading={setLoadingFooter}
                      breadcrumbs={breadcrumbs}
                    />,
                    document.getElementById("Footer")
                  )}
              </>
            </Suspense>
          </ThemeProvider>
        </IntegrationQuoteContext.Provider>
      )}
    </>
  );
};

render(<LayoutComponent />, document.getElementById("Layout"));
