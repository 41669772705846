import TranslationsInterface from "interfaces/TranslationsInterface";

export const getTranslation = (translations: Array<TranslationsInterface>, key: string): string => {
  if (translations && translations.length > 0) {
    const translation = translations.filter((t: any) => t.Key === key)[0];

    return translation ? translation.Value : key.toUpperCase();
  }
  return "";
};
